import React from 'react';
import TableRowAddress from './TableRowAddress';
import TableRowOffer from './TableRowOffer';
import TableRowArticle from './TableRowArticle';

class TableUtils {
    static prepareTableRowDataAddress = (requestData) => {
        return requestData.map((data) => (
            <TableRowAddress
                id={data['id']}
                key={data['id']}
                col_customer_nr={data['number']}
                col_email={data['email']}
                col_name={data['forename'] + ' ' + data['lastname']}
                col_street={data['street'] + ' ' + data['number_1']}
                col_zip_city={data['zip'] + ' ' + data['city']}
            />
        ));
    };

    static prepareTableRowDataArticle = (requestData) => {
        return requestData.map((data) => (
            <TableRowArticle
                id={data['id']}
                key={data['id']}
                col_article_nr={data['number']}
                col_description={data['description']}
                col_name={data['name']}
                col_manufacturer={data['manufacturer']}
                col_producttype={data['producttype']}
            />
        ));
    };

    static prepareTableRowDataOffer = (requestData) => {
        return requestData.map((data) => (
            <TableRowOffer
                id={data['id']}
                key={data['id']}
                col_number={data['number']}
                col_date={data['date']}
                col_description={data['description']}
                col_internal_note={data['intern_description']}
            />
        ));
    };
}

export default TableUtils;
