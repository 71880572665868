import React from 'react';
import BannerStart from '../ImagesFiles/header_backend_3.png';
import BannerFlex from '../../Website/ImagesFiles/banner-flexdruck-1.png';
import BannerLaser from '../../Website/ImagesFiles/banner-laser.png';
import BannerContact from '../../Website/ImagesFiles/banner_kontakt.png';
import TopnaviFrontend from "./TopnaviFrontend";
import {useLocation} from "react-router-dom";

const FromtendHeader = () => {

    let banner;
    const location = useLocation();
    const pathname = location.pathname.slice(1);

    switch (pathname) {
        case 'flexdruck':
            banner = BannerFlex;
            break;
        case 'lasergravur':
            banner = BannerLaser;
            break;
        case 'kontakt':
            banner = BannerContact;
            break;
        default:
            banner = BannerStart;
    }

    return (
        <section>

            <h2 className="font-semibold tracking-tight text-center text-gray-700 md:pt-0 pt-5">
                <picture>
                    <img className="w-auto inset-0 object-cover object-center"
                         src={banner}
                         alt="" loading=""/>
                </picture>
            </h2>
            <div className="text-sm text-gray-700 text-right border-t-gray-180 border-b-gray-180 border-b-2">
                {<TopnaviFrontend/>}
            </div>
        </section>
    );

}

export default FromtendHeader;