import React from "react";

class InputCheckbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            label: props.label
        };

    }

    handleChangeCheckbox = (event) => {
        const checked = event.target.checked;
        this.props.onChange(this.props.id, checked);
    }

    getClasses = () => {
        let classes = 'block text-gray-500 font-bold md:text-right';
        if (true === this.props.show) {
            if (false !== this.props.error && false === this.props.checked) {
                classes = 'block text-red-500 font-bold md:text-right';
            }
        }
        return classes;
    }

    rawMarkup = (html) => {
        return {__html: html};
    }

    getLabel = () => {
        let label = this.state.label;
        if ('' !== this.props.link) {
            label = this.state.label.replace(this.props.change, '<a href="' + this.props.link + '" class="underline">' + this.props.change + '</a>');
            label = <span dangerouslySetInnerHTML={this.rawMarkup(label)}/>
        }
        return label;
    }

    render() {
        return (
            <div className="md:flex justify-end mt-2">
                <div className="md:w-full flex justify-end">
                    <label
                        className={this.getClasses()}
                        htmlFor={this.props.id}>
                    <input
                        id={this.props.id}
                        className="shadow bg-purple-500 hover:bg-purple-400 focus:outline-none text-white font-bold rounded"
                        type="checkbox"
                        checked={this.state.checked}
                        onClick={this.handleChangeCheckbox}/>
                        &nbsp;&nbsp;
                        {this.getLabel()}
                    </label>
                </div>
            </div>
        )
            ;
    }

}

export default InputCheckbox;