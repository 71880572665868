import React from 'react';
import FrontendHeader from "../../Website/Frontend/FrontendHeader";
import FrontendFooter from "../../Website/Frontend/FrontendFooter";

const FrontendDatenschutzerklaerung = () => {

    return (
        <main className="md:pt-1 pb-20">
            <div className='flex justify-center max-w-7xl flex-col mx-auto bg-white'>
                {<FrontendHeader/>}
                <section id="showcase-laser"
                         className="bg-cover bg-center h-36 flex items-center justify-center text-white bg-[#3f888f]">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold">Datenschutzerkl&auml;rung</h1>
                    </div>
                </section>
                <section>
                    <div className="container mx-auto p-6 ">

                        <div className="mb-10 border-2 rounded-xl p-5">
                            <h2 className="text-2xl font-semibold mt-6 mb-4">1. Datenschutz auf einen Blick</h2>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Allgemeine Hinweise</h3>
                            <p className="mb-4">Die folgenden Hinweise geben einen einfachen &uuml;berblick
                                dar&uuml;ber, was mit
                                Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen.
                                Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert
                                werden
                                k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer
                                unter
                                diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.</p>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Datenerfassung auf unserer Website</h3>

                            <p className="font-semibold mb-2">Wer ist verantwortlich f&uuml;r die Datenerfassung auf
                                dieser
                                Website?</p>
                            <p className="mb-4">Die Datenverarbeitung auf dieser Website erfolgt durch den
                                Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Impressum dieser Website
                                entnehmen.</p>

                            <p className="font-semibold mb-2">Wie erfassen wir Ihre Daten?</p>
                            <p className="mb-4">Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                                mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular
                                eingeben.</p>
                            <p className="mb-4">Andere Daten werden automatisch beim Besuch der Website durch unsere
                                IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser,
                                Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                                automatisch, sobald Sie unsere Website betreten.</p>

                            <p className="font-semibold mb-2">Wof&uuml;r nutzen wir Ihre Daten?</p>
                            <p className="mb-4">Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
                                Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres
                                Nutzerverhaltens
                                verwendet werden.</p>

                            <p className="font-semibold mb-2">Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</p>
                            <p className="mb-4">Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
                                Herkunft,
                                Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie
                                haben
                                au&szlig;erdem ein Recht, die Berichtigung, Sperrung oder L&ouml;schung dieser Daten zu
                                verlangen.
                                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an
                                uns
                                wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
                                Aufsichtsbeh&ouml;rde zu.</p>

                            <h2 className="text-2xl font-semibold mt-6 mb-4">2. Allgemeine Hinweise und
                                Pflichtinformationen</h2>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Datenschutz</h3>
                            <p className="mb-4">Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
                                Daten
                                sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der
                                gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.</p>
                            <p className="mb-4">Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
                                Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich
                                identifiziert
                                werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche
                                Daten wir erheben
                                und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das
                                geschieht.</p>
                            <p className="mb-4">Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B.
                                bei
                                der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein
                                l&uuml;ckenloser Schutz
                                der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</p>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Hinweis zur verantwortlichen Stelle</h3>
                            <p className="mb-4">Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
                                Website
                                ist:</p>
                            <p className="mb-4"><strong>Lagocki - Refinement, Textile & Print</strong><br/>
                                Auf der Horst 32<br/>
                                48308 Senden<br/>
                                Telefon: 02598 / 83 19 771<br/>
                                E-Mail: info[at]lagocki.de</p>
                            <p className="mb-4">Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person,
                                die
                                allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von
                                personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. &auml;.) entscheidet.</p>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Widerruf Ihrer Einwilligung zur
                                Datenverarbeitung</h3>
                            <p className="mb-4">Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
                                ausdr&uuml;cklichen
                                Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung jederzeit
                                widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
                                Rechtm&auml;&szlig;igkeit
                                der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.</p>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Beschwerderecht bei der zust&auml;ndigen
                                Aufsichtsbeh&ouml;rde</h3>
                            <p className="mb-4">Im Falle datenschutzrechtlicher Verst&ouml;&szlig;e steht dem
                                Betroffenen ein
                                Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu. Zust&auml;ndige
                                Aufsichtsbeh&ouml;rde in
                                datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in
                                dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie
                                deren Kontaktdaten k&ouml;nnen folgendem Link entnommen werden: <a
                                    href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                                    className="text-blue-600 hover:underline">BfDI - Bundesbeauftragter f&uuml;r den
                                    Datenschutz und die Informationsfreiheit</a>.</p>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Recht auf Daten&uuml;bertragbarkeit</h3>
                            <p className="mb-4">Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
                                oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich oder an einen
                                Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen.
                                Sofern Sie
                                die direkte &uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen,
                                erfolgt
                                dies nur, soweit es technisch machbar ist.</p>

                            <h3 className="text-xl font-semibold mt-4 mb-2">SSL- bzw. TLS-Verschl&uuml;sselung</h3>
                            <p className="mb-4">Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz
                                der &uuml;bertragung
                                vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
                                Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine
                                verschl&uuml;sselte
                                Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf
                                "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
                            <p className="mb-4">Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
                                die Daten,
                                die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Auskunft, Sperrung, L&ouml;schung</h3>
                            <p className="mb-4">Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
                                das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen
                                Daten,
                                deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht
                                auf
                                Berichtigung, Sperrung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen
                                zum
                                Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im Impressum
                                angegebenen Adresse an uns wenden.</p>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Widerspruch gegen Werbe-Mails</h3>
                            <p className="mb-4">Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
                                Kontaktdaten zur &uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und
                                Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten
                                sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von
                                Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>

                            <h2 className="text-2xl font-semibold mt-6 mb-4">3. Datenerfassung auf unserer Website</h2>

                            <h3 className="text-xl font-semibold mt-4 mb-2">Cookies</h3>
                            <p className="mb-4">Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
                                richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen
                                dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind
                                kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
                                speichert.</p>

                            <p className="mb-4">
                                Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie
                                werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Andere Cookies bleiben auf
                                Ihrem
                                Endger&auml;t gespeichert, bis Sie diese l&ouml;schen. Diese Cookies erm&ouml;glichen es
                                uns, Ihren
                                Browser beim n&auml;chsten Besuch wiederzuerkennen.
                            </p>
                            <p className="mb-4">
                                Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies
                                informiert
                                werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r
                                bestimmte
                                F&auml;lle oder generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
                                Cookies beim
                                Schlie&szlig;en des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die
                                Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
                            </p>

                            <h3 className="text-2xl font-semibold mb-4">Server-Log-Dateien</h3>
                            <p className="mb-4">
                                Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                                Server-Log-Dateien, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:
                            </p>
                            <ul className="list-disc list-inside mb-4">
                                <li>Browsertyp und Browserversion</li>
                                <li>verwendetes Betriebssystem</li>
                                <li>Referrer URL</li>
                                <li>Hostname des zugreifenden Rechners</li>
                                <li>Uhrzeit der Serveranfrage</li>
                                <li>IP-Adresse</li>
                            </ul>
                            <p className="mb-4">Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
                                nicht
                                vorgenommen.</p>
                            <p className="mb-4">
                                Grundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die
                                Verarbeitung
                                von Daten zur Erf&uuml;llung eines Vertrags oder vorvertraglicher Ma&szlig;nahmen
                                gestattet.
                            </p>

                            <h3 className="text-2xl font-semibold mb-4">Kontaktformular</h3>
                            <p className="mb-4">
                                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                                Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung
                                der Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
                                geben
                                wir nicht ohne Ihre Einwilligung weiter.
                            </p>
                            <p className="mb-4">
                                Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit
                                ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
                                k&ouml;nnen
                                diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
                                an uns. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
                                Datenverarbeitungsvorg&auml;nge
                                bleibt vom Widerruf unber&uuml;hrt.
                            </p>
                            <p className="mb-4">
                                Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur
                                L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
                                f&uuml;r die
                                Datenspeicherung entf&auml;llt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
                                Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben
                                unber&uuml;hrt.
                            </p>

                            {/*<h2 className="text-3xl font-bold mb-6">4. Plugins und Tools</h2>*/}

                            {/*<h3 className="text-2xl font-semibold mb-4">Google reCAPTCHA</h3>*/}
                            {/*<p className="mb-4">*/}
                            {/*    Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites. Anbieter*/}
                            {/*    ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).*/}
                            {/*</p>*/}
                            {/*<p className="mb-4">*/}
                            {/*    Mit reCAPTCHA soll &uuml;berpr&uuml;ft werden, ob die Dateneingabe auf unseren Websites*/}
                            {/*    (z.B. in*/}
                            {/*    einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm*/}
                            {/*    erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand*/}
                            {/*    verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher*/}
                            {/*    die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B.*/}
                            {/*    IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer*/}
                            {/*    get&auml;tigte*/}
                            {/*    Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet.*/}
                            {/*</p>*/}
                            {/*<p className="mb-4">*/}
                            {/*    Die reCAPTCHA-Analysen laufen vollst&auml;ndig im Hintergrund. Websitebesucher werden*/}
                            {/*    nicht*/}
                            {/*    darauf hingewiesen, dass eine Analyse stattfindet.*/}
                            {/*</p>*/}
                            {/*<p className="mb-4">*/}
                            {/*    Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der*/}
                            {/*    Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor*/}
                            {/*    missbr&auml;uchlicher automatisierter Aussp&auml;hung und vor SPAM zu sch&uuml;tzen.*/}
                            {/*</p>*/}
                            {/*<p className="mb-4">*/}
                            {/*    Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerkl&auml;rung von Google*/}
                            {/*    entnehmen Sie folgenden Links: <a*/}
                            {/*    href="https://www.google.com/intl/de/policies/privacy/"*/}
                            {/*    className="text-blue-500 underline">Google Datenschutz</a> und <a*/}
                            {/*    href="https://www.google.com/recaptcha/intro/v3.html"*/}
                            {/*    className="text-blue-500 underline">Google reCAPTCHA</a>.*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </section>
                {<FrontendFooter/>}
            </div>
        </main>


    );
}

export default FrontendDatenschutzerklaerung;