import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CrudUtils from '../CRUD/CrudUtils';
import { useNavigate } from 'react-router';
import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';

const BackendOfferCreate = () => {
    const params = useParams();
    const navigate = useNavigate();
    const docNr = CrudUtils.createDocumentNumber('A');

    useEffect(() => {
        try {
            const offerData = {
                customer_id: params.customerid,
                document_number: docNr,
            };

            const timer = setTimeout(() => {
                CrudUtils.postData(offerData, 'offer/new').then(() => {
                    navigate('/backend/offer/edit/' + docNr);
                });
            }, 1000);
            return () => clearTimeout(timer);
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-24 flex items-center justify-between text-white bg-[#3f888f]"
                >
                    <div className="w-full">
                        <h3 className="text-gray-200 ml-5">Plesae wait while we create the offer</h3>
                    </div>
                </section>

                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendOfferCreate;
