import ListItem from './ListItem';
import React from 'react';
import ShowDocumentPositions from '../../Content/Backend/CRUD/ShowDocumentPositions';

class ListUtils {
    static prepareListDataMultiple = (listData, dataType) => {
        const tmp = [];
        listData.map((data) => {
            const tmpdata = (
                <ListItem
                    id={data['id']}
                    key={data['id']}
                    headlineCardHeader={data['headlineCardHeader']}
                    headline2={data['headline2']}
                    headline1={data['headline1']}
                    info1={data['info1']}
                    info2={data['info2']}
                    info3={data['info3']}
                    dataType={dataType}
                />
            );
            tmp.push(tmpdata);
        });
        return tmp;
    };

    static makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    static prepareListDataPositions = (data) => {
        const tmp = [];
        data.map((data) => {
            const tmpdata = (
                <ShowDocumentPositions
                    id={data.id}
                    key={data.id}
                    amount={data.amount}
                    article={data.article}
                    price={data.price}
                />
            );
            tmp.push(tmpdata);
        });
        return tmp;
    };
}

export default ListUtils;
