import React from 'react';
import {isTokenValid} from "../Auth";
import TopnaviBackendAdmin from "./TopnaviBackendAdmin";

const BackendHeader = () => {


    isTokenValid();

    return (
        <section>
            <div className="text-sm text-gray-700 text-right border-t-gray-180 border-b-gray-180 border-b-2">
                {<TopnaviBackendAdmin/>}
            </div>
        </section>
    );


}

export default BackendHeader;